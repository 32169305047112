<template>
  <div>
    <!-- <div>
      <van-nav-bar title="资讯详情" />
    </div> -->
    <!-- 内容 -->
    <div class="essay">
      <!-- <div class="essayTitle">{{ list.Title }}</div>
      <div class="rikind">{{ list.AKName }}</div>
      <div v-if="list.Poster"
        class="essayImg">
        <img :src="list.PosterUrl"
          alt="" />
      </div>
      <div class="essayContent"
        v-html="list.Content"></div>
      <div class="video"
        v-if="list.Video">
        <video style="width: 100%"
          :src="list.Video"
          controls="controls">
          您的浏览器不支持 video 标签。
        </video>
      </div> -->
      <!-- <van-button v-if="list.AllowSignup == 1"
        class="button"
        @click="Activity"
        round
        type="info">活动申请</van-button> -->
      <div>
        <img :src="details.PosterUrl"
          style="width:100%;height:290px;" />
        <!-- <van-swipe :autoplay="3000"
          @change="onChange">
          <van-swipe-item v-for="item in details.PosterUrl.split(',')"
            :key="item+'11'">
            <img :src="item"
              style="width:100%;height:290px;" />
          </van-swipe-item>
         
        </van-swipe> -->
        <div style="border-bottom: 10px solid #f8f8f8;padding:20px 0;">
          <div style="width:95%;margin:auto;">
            <div class="biaoti">
              <span class="title">{{details.Title}}</span>
              <span class="money">{{details.StatusDesc}} </span>
            </div>
            <div style="color:#31D984;margin:5px 0 10px;">
              <span>{{details.OrgName}}</span> |
              <span>{{details.ShopName}}</span>
            </div>
            <div class="time"
              v-if="details.ShopTypeName != ''">
              <van-cell icon="shop-o"
                style="padding:0;"
                color="#c8cad8">
                <template #title>
                  <span class="custom-title time"
                    style="font-size:16px;text">&nbsp;&nbsp;{{details.ShopTypeName}}</span>
                </template>
              </van-cell>
            </div>
            <div class="time">
              <van-cell icon="friends-o"
                style="padding:0;">
                <template #title>
                  <span class="custom-title time"
                    style="font-size:16px;">报名人数：{{details.EnrolNum}}/{{details.Quota}}</span>
                </template>
              </van-cell>
            </div>
            <div class="time">
              <van-cell icon="clock-o"
                style="padding:0;">
                <template #title>
                  <span class="custom-title time"
                    style="font-size:16px;">{{details.StartDate}} ~ {{details.EndDate}}</span>
                </template>
              </van-cell>
            </div>
          </div>
        </div>

        <div class="essay"
          style="width: 100%;">
          <div style="margin-bottom:20px;">
            <div class="zhuyao">—&nbsp;&nbsp;活动内容&nbsp;&nbsp;—</div>
          </div>
          <div class="essayContent"
            v-html="details.Content"></div>
          <div class="video"
            v-if="details.Video"
            style="text-align: center;">
            <video style="width: 95%;"
              :src="details.Video"
              controls="controls">
              您的浏览器不支持 video 标签。
            </video>
          </div>
        </div>
      </div>
      <div style="width:100%;">
        <van-button class="button"
          @click="Activity(details.PromotionId)"
          style="width:95%;"
          round
          type="info">活动申请</van-button>
      </div>

    </div>
  </div>
</template>

<script>
import { WxGetPromotion, WxEnrolPromotion } from "@/api/RealInfo";
import config from "@/config"
import Vue from "vue";
import { Toast } from "vant";
import { Dialog } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
Vue.use(Toast);
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      details: {},
      count: '',
      imgsList: [],
      list: [],
      strategy: {},
      landScape: [],
      url: config.hostUrl
    }
  },
  created () {
    this.wxGetPromotion()
  },
  methods: {
    //格式化日期
    filteryear (val) {
      // let gstime = val.activationDate.replace('T', ' ')
      if (val.StartDate != null && val.StartDate != '') {
        let gstime = val.StartDate.substring(0, 10);
        return gstime;
      }
    },
    onChange (index) {
      this.count = index;
    },
    wxGetPromotion () {
      console.log(this.$route.params);
      WxGetPromotion({ promotionId: this.$route.params.Id }).then((res) => {
        this.details = res.data.data
        this.details.StartDate = res.data.data.StartDate.substring(0, 10);
        this.details.EndDate = res.data.data.EndDate.substring(0, 10);
      })
    },
    // 活动报名
    Activity (row) {
      Dialog.confirm({
        title: '提示',
        message:
          '确定要参加该活动吗',
      })
        .then(() => {
          // 确认
          console.log(123);
          this.getEnrolPromotion(row)
        })
    },
    getEnrolPromotion: function (row) {
      console.log(row);
      WxEnrolPromotion({ OpenID: getOpenId(), PromotionId: row })
        .then((res) => {
          if (res.data.code == 0) {
            Dialog.alert({
              title: "提示",
              message: "报名成功!",
            }).then(() => {
              this.$router.go(-1)
            });
          } else {
            Toast.fail(res.data.msg);
          }
        })

    },
  }
}
</script>

<style scoped>
.custom-indicator {
  position: absolute;
  right: 15px;
  bottom: 15px;
  padding: 2px 5px;
  font-size: 14px;
  color: #ffffff;
  /* background: rgba(255, 255, 255, 0.788); */
}
.biaoti {
  display: flex;
  justify-content: space-between;
}
.title {
  color: #0c0c0c;
  font-size: 24px;
  font-weight: 550;
}
.money {
  line-height: 33px;
  font-family: PingFang SC;
  font-weight: 550;
  color: #fe5b47;
  font-size: 18px;
}
.time {
  /* display: flex;
  justify-content: space-between; */
  padding: 5px 0;
  color: #0c0c0c;
  font-weight: bold;
  line-height: 30px;
}
.zhuyao {
  font-size: 1.17em;
  font-weight: bold;
  margin-top: 1em;
  text-align: center;
}
.zhuyaoJd {
  font-size: 1.17em;
  font-weight: bold;
  margin-top: 1em;
  text-align: left;
  display: flex;
  justify-content: space-between;
}
.zhuyaoJd span {
  color: rgb(153, 153, 153);
  float: right;
  font-size: 12pt;
  font-weight: 300;
}
.waterfall {
  /* width: 95%; */
  margin: 10px auto;
  column-count: 2;
  column-gap: 0;
  column-width: auto;
}
.waterfall .list {
  margin: 0px 7px 10px 7px;
  break-inside: avoid;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 5px 17px 1px rgba(0, 0, 0, 0.07);
}
.list img {
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  /* height: 160px; */
  /* border-radius: 0px; */
}
.address {
  margin: 10px 0;
  color: #92600d;
  font-size: 16px;
}
</style>